import React from 'react';
import ReactMarkdown from 'react-markdown';
import 'github-markdown-css/github-markdown-light.css';

interface MessageProps {
  role: string;
  content: string;
  cost: string;
}

const MessageComponent: React.FC<MessageProps> = ({ role, content, cost }) => (
  <div className="message">
    <div className="message-sender">{role === "user" ? "You (你)" : "ChatGPT"}</div>

    {role === 'assistant' ? (
      <div className="markdown-body">
        <ReactMarkdown>{cost ? (content + "\n---\n" + cost) : content}</ReactMarkdown>
      </div>
    ) : (
      <div className="message-content-user">
        {content}
      </div>
    )
    }

  </div>
);

export default MessageComponent;
