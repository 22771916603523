import React from 'react';
import './App.css';

import ChatApp from './components/ChatApp';

function App() {
  return (
     <ChatApp />
  );
}

export default App;
